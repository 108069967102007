import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SiteImage from "../components/SiteImage"
import { Autoplay, EffectCards, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Moment from "react-moment"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./tournaments.scss"

const MadalaSwiper = ({ children, showCards, onTap }) =>
  showCards ? (
    <Swiper
      key={0}
      modules={[EffectCards, Pagination, Navigation]}
      slidesPerView={2}
      centeredSlides
      pagination={{
        clickable: true,
      }}
      navigation
      effect="cards"
      loop
      cardsEffect={{
        rotate: false,
        perSlideOffset: 70,
      }}
      autoplay={{
        pauseOnMouseEnter: true,
      }}
      onTap={onTap}
    >
      {children}
    </Swiper>
  ) : (
    <Swiper
      key={1}
      modules={[Autoplay, Pagination]}
      pagination={{
        clickable: true,
      }}
      loop
      spaceBetween={50}
    >
      {children}
    </Swiper>
  )

function MadalaSection({
  announceText,
  title,
  description,
  TournamentDetailBaseUrl,
  TournamentResultsBaseUrl,
  TournamentItems,
}) {
  const [showCards, setShowCards] = useState(false)

  const flipNegative = v => (v[0] === "-" ? v.slice(1) : `-${v}`)

  useEffect(() => {
    const onResize = () => {
      setShowCards(document.body.offsetWidth > 768)
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <section id="madala-section" className="section">
      <div className="container">
        <div className="is-flex is-flex-direction-column is-align-items-center py-6">
          <div className="tournament-announce-text-container">
            <div className="tournament-announce-text">{announceText}</div>
          </div>
          <h2 className="mb-5 has-text-centered has-text-silver-gradient">
            <div className="title is-2">{title}</div>
          </h2>
          <p className="has-text-centered">{description}</p>
        </div>

        <MadalaSwiper showCards={showCards}>
          {TournamentItems.map(
            ({ id, image, title, startTime, endTime, tournamentId }) => {
              const isEnded = new Date(endTime).getTime() < Date.now()
              const isStarted = new Date(startTime).getTime() <= Date.now()
              const link = `${
                isEnded ? TournamentResultsBaseUrl : TournamentDetailBaseUrl
              }${tournamentId}`
              return (
                <SwiperSlide key={id}>
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    className="main-container has-text-white m-2"
                    target="_blank"
                  >
                    <div className="content-container">
                      <div className="image-container">
                        <SiteImage image={image} alt="" />
                      </div>
                      <div className="is-flex is-flex-direction-column">
                        <div className="m-5 has-text-white title is-5">
                          {title}
                        </div>
                      </div>
                      <div className="mx-5">
                        <div className="columns is-multiline is-vcentered">
                          <div className="column is-narrow-widescreen is-full-desktop is-full-tablet is-full-mobile is-narrow-fullhd">
                            <div className="is-flex is-align-items-flex-end">
                              <div>
                                {isEnded
                                  ? "Ended"
                                  : isStarted
                                  ? "Ends in"
                                  : "Starts in"}
                              </div>
                              {isEnded ? null : (
                                <div className="ml-3">
                                  <Moment
                                    interval={1000}
                                    date={
                                      new Date(isStarted ? endTime : startTime)
                                    }
                                    format={
                                      Math.abs(
                                        new Date(
                                          isStarted ? endTime : startTime
                                        ).getTime() - Date.now()
                                      ) >
                                      60 * 60 * 1000
                                        ? "H:mm:ss"
                                        : "mm:ss"
                                    }
                                    durationFromNow
                                    filter={flipNegative}
                                    element={({ children }) => (
                                      <div className="timer-info-row">
                                        {children.split("").map((t, i) => (
                                          <React.Fragment key={i}>
                                            {isNaN(t) ? (
                                              <div className="time-left-text">
                                                {t}
                                              </div>
                                            ) : (
                                              <div className="gradient-box-container">
                                                <div className="gradient-box time-elapsed-number">
                                                  {t}
                                                </div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="column is-narrow">
                            <div className="is-flex is-align-items-flex-end">
                              <div className="mr-2">
                                <Moment
                                  date={
                                    new Date(isStarted ? endTime : startTime)
                                  }
                                  format={"L"}
                                />
                              </div>
                              <div>
                                <Moment
                                  date={
                                    new Date(isStarted ? endTime : startTime)
                                  }
                                  format={"LT"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="m-5 button is-gradient is-family-secondary is-rounded py-0">
                        {isEnded ? "See Results" : "Join Now"}
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              )
            }
          )}
        </MadalaSwiper>
      </div>
    </section>
  )
}

function ExploreSection({ announceText, title, description, TournamentTypes }) {
  const renderFrame = () => (
    <svg
      viewBox="0 0 294 119"
      fill="none"
      className="tournament-type-background"
    >
      <path
        d="M1.05 1.716h113.377v.242c0 2.628 2.983 4.773 6.638 4.773h51.87c3.655 0 6.638-2.145 6.638-4.773v-.242H292.95v116.437H179.503c-.504-2.276-3.249-4.018-6.554-4.018h-51.87c-3.305 0-6.05 1.742-6.554 4.018H1.05V83.855h.35c3.655 0 4.286-2.144 4.286-4.773V41.784c0-2.628-.63-4.773-4.286-4.773h-.35V1.696M0 .941v36.956c.448-.08.91-.14 1.4-.14 3.081 0 3.236 1.802 3.236 4.017v37.298c0 2.216-.155 4.018-3.236 4.018-.49 0-.952-.06-1.4-.14v35.958h115.477c0-2.215 2.507-4.018 5.588-4.018h51.87c3.081 0 5.588 1.803 5.588 4.018H294V.951H178.327c.112.322.196.655.196.997 0 2.215-2.507 4.018-5.588 4.018v.765h-51.87c-3.081 0-5.588-2.568-5.588-4.783 0-.352.084-.675.196-.997H0v-.01z"
        fill="url(#prefix__paint0_linear_9308_151560)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_9308_151560"
          x1={147}
          y1={118.908}
          x2={147}
          y2={0.941}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3772FF" />
          <stop offset={1} stopColor="#E031CA" />
        </linearGradient>
      </defs>
    </svg>
  )
  return (
    <section id="explore-section" className="section">
      <div className="container">
        <div className="is-flex is-flex-direction-column is-align-items-center py-6">
          <div className="tournament-announce-text-container">
            <div className="tournament-announce-text">{announceText}</div>
          </div>
          <h2 className="mb-5 has-text-centered has-text-silver-gradient">
            <div className="title is-2">{title}</div>
          </h2>
          <p className="has-text-centered">{description}</p>
        </div>
        <div className="columns is-multiline">
          {TournamentTypes.map(({ title, description, id, icon }) => {
            return (
              <div
                className="column is-half is-flex is-flex-direction-column is-align-items-center"
                key={id}
              >
                <div className="tournament-type-container">
                  {renderFrame()}
                  <SiteImage
                    className="tournament-type-icon"
                    width={"100px"}
                    height={"100px"}
                    alt=""
                    image={icon}
                  />
                  <div className="tournament-type-title">
                    <div>{title}</div>
                  </div>
                  <div>{description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const HighlightsSwiper = ({ children }) => (
  <Swiper
    key={1}
    slidesPerView={"auto"}
    spaceBetween={40}
    effect={"slide"}
    loop
    freeMode
    speed={15000}
    autoplay={{
      delay: 1,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
      waitForTransition: true,
      stopOnLastSlide: false,
    }}
    modules={[Autoplay, Pagination]}
  >
    {children}
  </Swiper>
)

function HighlightsSection({
  announceText,
  title,
  description,
  slides,
  joinLink,
}) {
  return (
    <section id="highlight-section" className="section">
      <div className="container">
        <div className="is-flex is-flex-direction-column is-align-items-center py-6 px-3">
          <div className="tournament-announce-text-container">
            <div className="tournament-announce-text">{announceText}</div>
          </div>
          <h2 className="mb-5 has-text-centered has-text-silver-gradient">
            <div className="title is-2">{title}</div>
          </h2>
          <p className="has-text-centered">{description}</p>
        </div>
        <HighlightsSwiper>
          {slides.map(image => (
            <SwiperSlide key={image.id} style={{ width: "auto" }}>
              <div>
                <SiteImage alt="" image={image} />
              </div>
            </SwiperSlide>
          ))}
        </HighlightsSwiper>
        <div className="is-flex is-justify-content-center">
          <a
            href={joinLink.link}
            target="_blank"
            rel="noreferrer"
            className="m-5 button is-gradient is-family-secondary has-text-white is-rounded"
          >
            {joinLink.image && (
              <SiteImage
                className="mx-3"
                width={16}
                height={16}
                alt=""
                image={joinLink.image}
              />
            )}
            {joinLink.title}
          </a>
        </div>
      </div>
    </section>
  )
}

function HeroSection({ heroSlides }) {
  const [swiper, setSwiper] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const swiperInstance = swiper
    const updateIndex = () => setCurrentSlide(swiper.realIndex)
    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex)
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex)
      }
    }
  }, [swiper])
  return (
    <section id="hero" className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <Swiper
            onSwiper={setSwiper}
            a11y={false}
            shortSwipes={false}
            spaceBetween={50}
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            style={{
              padding: 0,
            }}
          >
            {heroSlides.map((hero, i) => (
              <SwiperSlide key={i}>
                {hero.background && (
                  <SiteImage
                    image={hero.background}
                    className="hero-background"
                    alt=""
                  />
                )}
                <div>
                  <div
                    className="columns is-desktop"
                    style={{ boxSizing: "border-box" }}
                  >
                    <div className="column is-7-desktop is-flex is-flex-direction-column is-align-items-center">
                      <h1 className="mb-5 has-text-centered has-text-silver-gradient">
                        <div className="title is-2">
                          {hero.title.split("\n").map((t, i) => (
                            <div key={`tournament-title-${i}`}>{t}</div>
                          ))}
                        </div>
                      </h1>
                      <p className="has-text-centered">{hero.description}</p>
                      <a
                        href={hero.link}
                        className="button is-gradient is-large is-family-secondary my-6 px-6"
                        {...(hero.link.startsWith("http")
                          ? { target: "_blank", rel: "noreferrer" }
                          : {})}
                      >
                        {hero.linkTitle}
                      </a>
                    </div>
                    <div className="column is-5-desktop is-flex is-justify-content-center">
                      <SiteImage
                        image={hero.heroImage}
                        objectFit={"contain"}
                        className="hero-image"
                        width="100%"
                        height="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-4 is-flex is-justify-content-center">
            {heroSlides.map((hero, i) => (
              <div
                className={`mx-3 p-3 is-flex is-flex-direction-column is-align-items-center thumbnail ${
                  currentSlide === i ? "active" : ""
                }`}
                key={hero.id}
                onClick={() => swiper?.slideTo(i)}
              >
                <div className="is-hidden-mobile image is-128x128">
                  <SiteImage
                    image={hero.heroImage}
                    width="128px"
                    height="128px"
                    alt=""
                  ></SiteImage>
                </div>{" "}
                <div className="is-hidden-tablet image is-64x64">
                  <SiteImage
                    image={hero.heroImage}
                    width="64px"
                    height="64px"
                    alt=""
                  ></SiteImage>
                </div>
                <div className="is-size-7 has-text-centered">
                  {["Overview", "Madala Challenges", "Easy Tournaments"][i]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

function VideoSection({
  announceText,
  title,
  description,
  videoLinks,
  buttonLink,
}) {
  return (
    <section id="tournament-video-section" className="section">
      <div className="container p-5">
        <div className="is-flex is-flex-direction-column is-align-items-center py-6">
          <div className="tournament-announce-text-container">
            <div className="tournament-announce-text">{announceText}</div>
          </div>
          <h2 className="mb-5 has-text-centered has-text-silver-gradient">
            <div className="title is-2">{title}</div>
          </h2>
          <p className="has-text-centered">{description}</p>
          <div className="columns mt-6 is-multiline is-align-self-stretch">
            {videoLinks.map(({ id, link }) => {
              return (
                <div
                  className="column is-half is-flex is-flex-direction-column is-align-items-stretch"
                  key={id}
                >
                  <div className="iframe-container">
                    <iframe
                      src={link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )
            })}
          </div>
          <a
            href={buttonLink.link}
            target="_blank"
            rel="noreferrer"
            className="m-5 button is-gradient is-family-secondary has-text-white is-rounded"
          >
            {buttonLink.title}{" "}
          </a>
        </div>
      </div>
    </section>
  )
}

function FaqItem({ question, answer }) {
  const [isActive, setIsActive] = useState(false)

  const plusIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint0_radial_10642_32618)"
      />
      <rect
        width="32"
        height="32"
        rx="16"
        fill="url(#paint1_radial_10642_32618)"
      />
      <path
        d="M16 24V16M16 16V8M16 16H8M16 16H24"
        stroke="url(#paint2_radial_10642_32618)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0947 16) rotate(90) scale(12.5176 12.595)"
        >
          <stop stopColor="#25224F" stopOpacity="0.64" />
          <stop offset="1" stopColor="#1C1942" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0947 16) rotate(90) scale(42.6353 42.8473)"
        >
          <stop stopColor="#D7C2FF" stopOpacity="0" />
          <stop offset="1" stopColor="#3A376B" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_10642_32618"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 16) rotate(90) scale(8)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.46" />
        </radialGradient>
      </defs>
    </svg>
  )

  const minusIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#13002E" />
      <path
        d="M23 16H16H9"
        stroke="#ECECEC"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )

  return (
    <div className={`item-container p-3 mb-3 ${isActive ? "active" : ""}`}>
      <div className="background"></div>
      <div
        className="item-question has-text-weight-semibold is-flex is-justify-content-space-between is-align-items-center"
        onClick={() => setIsActive(p => !p)}
      >
        <div>{question}</div>
        {isActive ? minusIcon : plusIcon}
      </div>
      {isActive && (
        <div className="mt-5">
          <ReactMarkdown remarkPlugins={[remarkGfm]} transformLinkUri={null}>
            {answer}
          </ReactMarkdown>
        </div>
      )}
    </div>
  )
}

function FaqSection({ announceText, title, faqItems }) {
  return (
    <section id="faq" className="section">
      <div className="container">
        <div className="is-flex is-flex-direction-column is-align-items-center py-6">
          <div className="tournament-announce-text-container">
            <div className="tournament-announce-text">{announceText}</div>
          </div>
          <h2 className="mb-5 has-text-centered has-text-silver-gradient">
            <div className="title is-2">
              {title.split("\n").map((t, i) => (
                <div key={i}>{t}</div>
              ))}
            </div>
          </h2>
        </div>
        <div>
          {faqItems.map(({ question, answer, id }) => (
            <FaqItem question={question} answer={answer.data.answer} key={id} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default function Tournaments({
  data: {
    strapiTournament: {
      seo,
      hero: heroSlides,
      Madala,
      Explore,
      Highlights,
      Video,
      Faq,
    },
  },
}) {
  return (
    <Layout showNewsletterCTA>
      <Seo
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname={"tournaments"}
      />
      <HeroSection heroSlides={heroSlides} />
      <MadalaSection
        title={Madala.title}
        announceText={Madala.announceText}
        description={Madala.description}
        TournamentDetailBaseUrl={Madala.TournamentDetailBaseUrl}
        TournamentResultsBaseUrl={Madala.TournamentResultsBaseUrl}
        TournamentItems={Madala.TournamentItems}
      />
      <VideoSection
        announceText={Video.announceText}
        title={Video.title}
        description={Video.description}
        videoLinks={Video.videoLinks}
        buttonLink={Video.buttonLink}
      />
      <ExploreSection
        title={Explore.title}
        announceText={Explore.announceText}
        description={Explore.description}
        TournamentTypes={Explore.TournamentType}
        TournamentResultsBaseUrl={Explore.TournamentResultsBaseUrl}
        TournamentItems={Explore.TournamentItems}
      />
      <HighlightsSection
        title={Highlights.title}
        announceText={Highlights.announceText}
        description={Highlights.description}
        slides={Highlights.slides}
        joinLink={Highlights.joinLink}
      />
      <FaqSection
        announceText={Faq.announceText}
        title={Faq.title}
        faqItems={Faq.faqItem}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHome {
      appLinks {
        title
        subtitle
        background {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        storeLinks {
          id
          title
          link
          image {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    strapiTournament {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      hero {
        id
        title
        description
        link
        linkTitle
        heroImage {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        background {
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Madala {
        announceText
        title
        description
        TournamentDetailBaseUrl
        TournamentResultsBaseUrl
        TournamentItems {
          id
          title
          image {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData(height: 700)
              }
            }
          }
          startTime
          endTime
          tournamentId
          videoLink
        }
      }
      Explore {
        announceText
        title
        description
        TournamentType {
          id
          title
          description
          icon {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Highlights {
        announceText
        title
        description
        slides {
          id
          ext
          localFile {
            url
            childImageSharp {
              gatsbyImageData(height: 330)
            }
          }
        }
        joinLink {
          title
          link
          image {
            ext
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Video {
        announceText
        title
        description
        videoLinks {
          id
          link
        }
        buttonLink {
          title
          link
        }
      }
      Faq {
        announceText
        title
        faqItem {
          id
          question
          answer {
            data {
              answer
            }
          }
        }
      }
    }
  }
`
